import React from "react";
import { graphql } from "gatsby";
import Helmet from "react-helmet";

import "./noty/reset.scss";
import "./noty/style.scss";
import "./noty/responsive.scss";
import "./noty/animation.css";

import ogp from "./noty/ogp-noty.png";

const NotyLpPage = ({ data }) => {
  const pageTitle = "のっティ時刻表アプリ -honmushi blog";
  const pageDescription =
    "石川県野々市市のコミュニティバス「のっティ」と「のんキー」の時刻表スマホアプリです。";

  return (
    <>
      <Helmet
        htmlAttributes={{ lang: "ja" }}
        title={pageTitle}
        meta={[
          { name: "description", content: pageDescription },
          { name: "keywords", content: data.site.siteMetadata.keywords },
          { property: "og:title", content: pageTitle },
          { property: "og:description", content: pageDescription },
          { property: "og:url", content: data.site.siteMetadata.siteUrl },
          { property: "og:type", content: "website" },
          { property: "og:site_name", content: data.site.siteMetadata.title },
          { property: "twitter:card", content: "summary_large_image" },
          {
            property: "twitter:site",
            content: data.site.siteMetadata.twitterUsername,
          },
          {
            property: "google-site-verification",
            content: data.site.siteMetadata.google_site_verification,
          },
          {
            property: "og:image",
            content: data.site.siteMetadata.siteUrl + ogp,
          },
          { httpEquiv: "expires", content: "43200" },
        ]}>
        <script
          async
          src="//pagead2.googlesyndication.com/pagead/js/adsbygoogle.js"></script>
        <script async type="text/javascript" src="/lp-assets/notty/common.js" />
        <link rel="preconnect" href="https://fonts.gstatic.com" />
        <link
          href="https://fonts.googleapis.com/css2?family=M+PLUS+Rounded+1c:wght@400;500&display=swap"
          rel="stylesheet"
        />
        <style>{`
  body {
    font-family: "M PLUS Rounded 1c", "Rounded Mplus 1c", sans-serif;
    color: #333333;
    background-color: #d4fdff;
    background-size: cover;
    min-height: 100vh;
    animation: allfade 3s ease 0s 1 normal;
    -webkit-animation: allfade 3s ease 0s 1 normal;
  }
  body:after {
    /*擬似要素をつくる*/
    position: fixed;
    /*固定配置*/
    top: 0;
    left: 0;
    /*左上に固定*/
    width: 100%;
    height: 100%;
    /*画面全体を覆う*/
    content: "";
    background: #d4fdff;
    background: linear-gradient(
      -15deg,
      #fff 0%,
      #d4fdff 40%,
      #d0e4f3 50%,
      #569de1 80%,
      #2f65be 100%
    );
    z-index: -999;
  }

  @keyframes allfade {
    0% {
      opacity: 0;
    }

    100% {
      opacity: 1;
    }
  }

  @-webkit-keyframes allfade {
    0% {
      opacity: 0;
    }

    100% {
      opacity: 1;
    }
  }
`}</style>
      </Helmet>

      <div id="lp-notty">
        <div className="wrapper">
          <div id="header">
            <header className="header">
              <div className="butterfly01"></div>
              <div className="butterfly02"></div>
              <div className="logo-app-area">
                <div>
                  <img
                    className="logo"
                    src="/lp-assets/notty/image/logo01.svg"
                    alt="のっティ＆のんキー時刻表アプリ"
                    width="300"
                    height="100"
                  />
                </div>
                <div className="top-download-area">
                  <div className="top-desc-download">
                    <div className="dl-button gp-b">
                      <a
                        target="_blank"
                        rel="noopener"
                        href="https://play.google.com/store/apps/details?id=com.honmushi.notybus&pcampaignid=pcampaignidMKT-Other-global-all-co-prtnr-py-PartBadge-Mar2515-1">
                        <img
                          alt="Google Play で手に入れよう"
                          src="/lp-assets/notty/image/dlb_google.png"
                          width="325"
                          height="125"
                        />
                      </a>
                    </div>

                    <div className="dl-button as-b">
                      <a
                        target="_blank"
                        rel="noopener"
                        className="app-link"
                        href="https://apps.apple.com/us/app/%E3%81%AE%E3%81%A3%E3%83%86%E3%82%A3%E6%99%82%E5%88%BB%E8%A1%A8/id1477982095?l=ja&amp;ls=1">
                        <img
                          alt="app storeからダウンロード"
                          width="300"
                          height="89"
                          src="/lp-assets/notty/image/dlb_app.png"
                        />
                      </a>
                    </div>
                  </div>
                </div>
              </div>
            </header>
          </div>

          <div className="container">
            <div className="about">
              <div className="contents">
                <h2>
                  <span>の</span>っティ時刻表アプリって？
                </h2>
                <div className="about-all">
                  <div className="about-box box_animation">
                    <img
                      className="about-image"
                      src="/lp-assets/notty/image/about01.png"
                      alt="時刻表が見やすい！"
                      width="220"
                      height="220"
                    />
                    <div className="about-content">
                      <div className="about-caption">
                        <span className="about-line">スマホで見やすい！</span>
                      </div>
                      <p className="about-text">
                        野々市市コミュニティバス「のっティ」と「のんキー」の時刻表がスマホで確認できるアプリです。
                        <br />
                        過ぎた時刻はグレーで表示されるため、時刻表がとても見やすく確認できます。
                      </p>
                    </div>
                  </div>
                  <div className="about-box box_animation">
                    <img
                      className="about-image"
                      src="/lp-assets/notty/image/about02.png"
                      alt="のんキーの運賃も確認！"
                      width="220"
                      height="220"
                    />
                    <div className="about-content">
                      <div className="about-caption">
                        <span className="about-line">運賃もわかる！</span>
                      </div>
                      <p className="about-text">
                        のっティは運賃が一律100円ですが、のんキーは乗車区間によって運賃が異なります。
                        <br />
                        のんキーの目的地までの運賃をアプリ内で確認することができます。
                      </p>
                    </div>
                  </div>
                  <div className="about-box box_animation">
                    <img
                      className="about-image"
                      src="/lp-assets/notty/image/about04.jpg"
                      alt="バス停の名前で検索！"
                      width="220"
                      height="220"
                    />
                    <div className="about-content">
                      <div className="about-caption">
                        <span className="about-line">バス停の名前で検索！</span>
                      </div>
                      <p className="about-text">
                        バス停は名前で検索することができます。
                        <br />
                        「バス停の名前はわかるけど、どのルートか分からない」という場合でも、目的のバス停を探すことができます。
                      </p>
                    </div>
                  </div>
                  <div className="about-box box_animation">
                    <img
                      className="about-image"
                      src="/lp-assets/notty/image/about03.png"
                      alt="バス停の場所を地図で確認！"
                      width="220"
                      height="220"
                    />
                    <div className="about-content">
                      <div className="about-caption">
                        <span className="about-line">地図で場所を確認！</span>
                      </div>
                      <p className="about-text">
                        バス停の位置がわからない場合でも、アプリ内の地図から確認することもできます。
                        <br />
                        バス停の場所を時刻表とあわせて確認することができます。
                      </p>
                    </div>
                  </div>
                  <div className="about-box box_animation">
                    <img
                      className="about-image"
                      src="/lp-assets/notty/image/about05.png"
                      alt="バス停をお気に入り登録！"
                      width="220"
                      height="220"
                    />
                    <div className="about-content">
                      <div className="about-caption">
                        <span className="about-line">お気に入り登録！</span>
                      </div>
                      <p className="about-text">
                        バス停は全てお気に入り登録ができます。
                        <br />
                        登録したバス停はお気に入りページに表示されるので、すぐに確認できます。
                        <br />
                        よく使うバス停は登録しておくと便利です。
                      </p>
                    </div>
                  </div>
                  <div className="about-box box_animation">
                    <img
                      className="about-image"
                      src="/lp-assets/notty/image/about06.png"
                      alt="ルート時刻表も確認！"
                      width="220"
                      height="220"
                    />
                    <div className="about-content">
                      <div className="about-caption">
                        <span className="about-line">ルート時刻表も確認！</span>
                      </div>
                      <p className="about-text">
                        バス停ごとだけではなく、ルートごとの時刻表も確認できます。
                        <br />
                        現在時刻に近い時間が表示され、縦に見ることで到着予定時間もわかります。
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="faq">
              <div className="contents">
                <h2>
                  <span>よ</span>くあるご質問
                </h2>
                <div className="faq-all">
                  <div className="faq-box box_animation">
                    <p className="faq-q">
                      <span className="faq-line">Q.利用料はかかりますか？</span>
                    </p>
                    <p className="faq-a">
                      ダウンロード・利用ともに無料でお使いいただけます。
                    </p>
                  </div>
                  <div className="faq-box box_animation">
                    <p className="faq-q">
                      <span className="faq-line">
                        Q.機能のリクエストはできる？
                      </span>
                    </p>
                    <p className="faq-a">
                      Google PlayやApp
                      Storeのレビュー、もしくは製作者のtwitterなどでご意見いただきましたら、対応できることがあるかもしれません。
                      <br />
                      個人制作のため全て実現できるとは限りませんが、よりよいアプリにするために、ぜひご意見お待ちしています。
                    </p>
                  </div>
                  <div className="faq-box box_animation">
                    <p className="faq-q">
                      <span className="faq-line">
                        Q.時刻表改訂はすぐ更新される？
                      </span>
                    </p>
                    <p className="faq-a">
                      情報に変更がないかは日々チェックしていますが、個人制作のため、更新が遅れてしまう可能性があります。
                      <br />
                      変更があるのに反映されていない場合や誤りがある場合などはご連絡いただけると幸いです。なるべく早めに対応します。
                    </p>
                  </div>
                </div>
              </div>
            </div>
            <div className="download">
              <div className="contents">
                <h2>
                  <span>ま</span>ずはダウンロード！
                </h2>
                <div className="dl-area box_animation">
                  <p className="dl-caption">
                    <span className="dl-line">ダウンロードはこちらから</span>
                  </p>
                  <div className="desc-download">
                    <div className="dl-button gp-b">
                      <a
                        target="_blank"
                        rel="noopener"
                        href="https://play.google.com/store/apps/details?id=com.honmushi.notybus&pcampaignid=pcampaignidMKT-Other-global-all-co-prtnr-py-PartBadge-Mar2515-1">
                        <img
                          alt="Google Play で手に入れよう"
                          src="/lp-assets/notty/image/dlb_google.png"
                          width="325"
                          height="125"
                        />
                      </a>
                    </div>
                    <div className="dl-button as-b">
                      <a
                        target="_blank"
                        rel="noopener"
                        className="app-link"
                        href="https://apps.apple.com/us/app/%E3%81%AE%E3%81%A3%E3%83%86%E3%82%A3%E6%99%82%E5%88%BB%E8%A1%A8/id1477982095?l=ja&amp;ls=1">
                        <img
                          alt="app storeからダウンロード"
                          width="300"
                          height="89"
                          src="/lp-assets/notty/image/dlb_app.png"
                        />
                      </a>
                    </div>
                  </div>
                  <p className="dl-text">
                    ※ダウンロード・ご利用は全て無料です。
                    <br />
                    ※本アプリは
                    <a
                      href="https://www.city.nonoichi.lg.jp/soshiki/10/155.html"
                      target="_blank"
                      rel="noopener">
                      <span>野々市市が提供するオープンデータ</span>
                    </a>
                    を使用して作成しています。
                  </p>
                </div>
              </div>
            </div>
            <div className="creator">
              <div className="contents">
                <h2>
                  <span>ア</span>プリ制作者の思い
                </h2>
                <div className="profile box_animation">
                  <p className="profile-caption">
                    <span className="profile-line">野々市市のエンジニア</span>
                  </p>
                  <img
                    className="profile-icon"
                    src="/lp-assets/notty/image/koshi-icon.jpg"
                    alt="こっし"
                    width="80"
                    height="80"
                  />
                  <a className="sns-margin" href="/">
                    <img
                      className="sns-button"
                      src="/lp-assets/notty/image/home.png"
                      alt="web site"
                    />
                  </a>
                  <a
                    className="sns-margin"
                    href="https://twitter.com/koshihonmushi">
                    <img
                      className="sns-button"
                      src="/lp-assets/notty/image/twitter.png"
                      alt="twitter"
                      width="30"
                      height="30"
                    />
                  </a>
                  <p className="profile-text">
                    私も野々市市に住んでおり、よくのっティを利用します。
                    <br />
                    石川県では車で移動する人が多いのですが、コミュニティバスを始めとする公共交通機関を利用することもできます。
                    <br />
                    のっティは運賃が常に100円でとても便利です。より便利になるといいなと思い、個人でのっティ時刻表アプリを作成しました。
                    <br />
                    他にも地域を快適にできるようなアプリやWebサービスの作成を考えています。ITで暮らしが快適になるように取り組みたいと思います。
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="header-board-pc">
          <img
            className="board"
            src="/lp-assets/notty/image/header-board-pc.png"
            alt="スマホからcheck！"
          />
        </div>
        <div className="bus-city">
          <img
            id="busimage"
            src="/lp-assets/notty/image/notty-bus1.png"
            alt="のっティ＆のんキー"
          />
          <div id="nonoichi-city">
            <ul className="nonoichi-city-ul">
              <li>
                <img
                  id="nonoichi-city1"
                  src="/lp-assets/notty/image/nonoichi-city1.png"
                  alt="野々市の街並み"
                />
              </li>
              <li>
                <img
                  id="nonoichi-city2"
                  src="/lp-assets/notty/image/nonoichi-city1.png"
                  alt="野々市の街並み"
                />
              </li>
            </ul>
            <ul className="nonoichi-city-ul">
              <li>
                <img
                  id="nonoichi-city3"
                  src="/lp-assets/notty/image/nonoichi-city1.png"
                  alt="野々市の街並み"
                />
              </li>
              <li>
                <img
                  id="nonoichi-city4"
                  src="/lp-assets/notty/image/nonoichi-city1.png"
                  alt="野々市の街並み"
                />
              </li>
            </ul>
          </div>
        </div>

        <div id="footer">
          <img
            className="footer-img-sp"
            src="/lp-assets/notty/image/footer-sp.png"
            alt="野々市の街並み"
            width="600"
            height="200"
          />
          <img
            className="footer-img-pc"
            src="/lp-assets/notty/image/footer-pc.png"
            alt="野々市の街並み"
            width="1920"
            height="240"
          />
          <footer className="footer">
            <img
              className="footer-logo"
              src="/lp-assets/notty/image/logo01.svg"
              alt="のっティ時刻表アプリ"
              width="300"
              height="212"
            />
            <div className="copyright">©️honmushi</div>
          </footer>
        </div>
      </div>
    </>
  );
};

export const query = graphql`
  query {
    site: site {
      siteMetadata {
        title
        keywords
        description
        siteUrl
        twitterUsername
        google_site_verification
      }
    }
  }
`;

export default NotyLpPage;
